/* Slide in/out animation for the Avatar */
.slide-avatar-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  .slide-avatar-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .slide-avatar-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .slide-avatar-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
  }
  
  /* Animation for the Menu */
  .menu-container {
    transition: margin-left 300ms;
  }
  .menu-expanded {
    margin-left: -56px;
  }
  